import React from 'react'

import { Icon } from '../Icon'
import { Typography } from '../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Message: React.FC<IProps> = ({
  title,
  description,
  variant,
  iconProps,
  className,
  dataTestId,
}): JSX.Element => {
  const getDataTestId = (): string => {
    if (dataTestId) return dataTestId

    const base = 'message'
    if (!title) return base

    return `${base}-${title?.toLowerCase().replaceAll(' ', '-')}`
  }

  return (
    <div data-testid={getDataTestId()} className={`${Styles.message} ${Styles[variant]} ${className || ''}`}>
      <div className={`${Styles.header} ${!description ? Styles.noMargin : ''}`}>
        {title && (
          <Typography className={Styles.title} variant="h2" element="p">
            {title}
          </Typography>
        )}

        <div className={Styles.spacer} />

        {iconProps && (
          <Icon
            data-testid="icon-button"
            className={Styles.icon}
            width={10}
            height={10}
            name={iconProps.icon}
            onClick={iconProps.onClick}
            linked
          />
        )}
      </div>

      {description && (
        <Typography className={Styles.description} variant="body-2" element="p">
          {description}
        </Typography>
      )}
    </div>
  )
}
