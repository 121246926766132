import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Button, ConfirmModal } from '../../../Components'
import ExpandableText from '../../../Components/ExpandableText'
import { Select } from '../../../Components/FormComponents/Select'
import { Icon } from '../../../Components/Icon'
import { Link } from '../../../Components/Link'
import { Message } from '../../../Components/Message'
import { PageContainer } from '../../../Components/PageContainer'
import { Table } from '../../../Components/Table'
import { useTranslationDecorator } from '../../../Hooks/useTranslationDecorator'
import { useExportWrpmLazyQuery, useGetBudgetGroupsQuery, useGetWrpmQuery } from '../../../Lib/graphql'
import { LayoutContext } from '../../../Providers/LayoutProvider'
import Colors from '../../../Theme/colors.module.scss'
import { InviteBudgetGroups } from './Components/InviteBudgetGroups'
import { PollingNotification } from './Components/PollingNotification'
import { ProgressInvitedEmployees } from './Components/ProgressInvitedEmployees'
import { SurveyResponseBudgetGroups } from './Components/SurveyResponseBudgetGroups'
import {
  calculateStatistics,
  defaultBudgetGroupOption,
  downloadAsFile,
  generateYearArray,
  getBudgetGroupOptions,
  getDefaultBudgetGroupNames,
  getTableConfig,
  isDataDefinitive,
  mapModalityEnumToIconName,
  transformWRPMData,
} from './helpers'
import {
  IBudgetGroup,
  IBudgetGroupOption,
  IBudgetGroupWithSurvey,
  IStatistics,
  ITransformedWRPMRow,
  IWRPMTableRow,
  IYearOptions,
} from './interfaces'
import Styles from './styles.module.scss'

export const WRPMOverview: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const { organizationId } = useContext(LayoutContext) // FIXME: how to inform the user when there is no organization_id?
  const { locale } = useTranslationDecorator()

  const environment = import.meta.env.REACT_APP_ENVIRONMENT
  const isProduction = environment === 'production'
  const showProgressInvitedEmployees = !isProduction
  const enableExportFeature = !isProduction

  const yearOptions = generateYearArray()
  const desiredYear = Math.max(2024, new Date().getFullYear() - 1)
  const defaultYear = yearOptions.find((option) => option.value === desiredYear) || yearOptions[0]

  const [exportWrpmQuery] = useExportWrpmLazyQuery()

  const [selectedBudgetGroup, setSelectedBudgetGroup] = useState<IBudgetGroupOption>(defaultBudgetGroupOption)
  const [selectedBudgetGroupNames, setSelectedBudgetGroupNames] = useState<string[]>([])
  const [budgetGroupOptions, setBudgetGroupOptions] = useState<IBudgetGroupOption[]>([])
  const [defaultBudgetGroupNames, setDefaultBudgetGroupNames] = useState<string[]>([])
  const [showExportButton, setShowExportButton] = useState<boolean>(false)
  const [showExportConfirmModal, setShowExportConfirmModal] = useState<boolean>(false)
  const [selectedYear, setSelectedYear] = useState<IYearOptions>(defaultYear)

  const [statistics, setStatistics] = useState<IStatistics>({
    lastInvitation: null,
    lastReminder: null,
    respondents: null,
    invitees: null,
    thresholdMet: null,
  })

  const [showSideDrawer, setShowSideDrawer] = useState<boolean>(false)
  const [showResponseSideDrawer, setShowResponseSideDrawer] = useState<boolean>(false)
  const [showMessage, setShowMessage] = useState<boolean>(true)

  const toggleSurvey = (): void => {
    setShowSideDrawer(!showSideDrawer)
  }

  const toggleResponse = (): void => {
    setShowResponseSideDrawer(!showResponseSideDrawer)
  }

  const toggleMessage = (): void => {
    setShowMessage(!showMessage)
  }

  const handleConfirmExportClick = (): void => {
    exportWrpmQuery({ variables: { organizationId: `${organizationId}`, forYear: selectedYear.value } })
      .then((response) => {
        const base64 = response.data?.reisbalansV2?.v2Organization?.wrpmExportFile?.xlsFile

        if (base64) downloadAsFile(base64)

        setShowExportConfirmModal(false)
      })
      .catch(() => {
        toast.error(t('An error has occurred'))
      })
  }

  const getKindCellContent = (item: ITransformedWRPMRow): JSX.Element => {
    return (
      <div className={Styles.modalityKindWrapper}>
        {item.kind ? <Icon name={mapModalityEnumToIconName(item.kind)} /> : ''} {item.humanized}
      </div>
    )
  }

  const createTableData = (transformedWRPMData: ITransformedWRPMRow[]): IWRPMTableRow[] => {
    const tableRows: IWRPMTableRow[] = []

    transformedWRPMData.forEach((item) => {
      tableRows.push({
        id: item.id,
        kind: getKindCellContent(item),
        commute_kms: `${item.commute_kms.toLocaleString('nl')} ${t('km')}`,
        commute_co2: `${item.commute_co2.toLocaleString('nl')} ${t('gram')}`,
        business_kms: `${item.business_kms.toLocaleString('nl')} ${t('km')}`,
        business_co2: `${item.business_co2.toLocaleString('nl')} ${t('gram')}`,
      })
    })

    return tableRows
  }

  const { data: budgetGroupsQuery } = useGetBudgetGroupsQuery({
    variables: { organizationId: `${organizationId}` },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    skip: !organizationId,
  })

  useEffect(() => {
    const budgetGroups = budgetGroupsQuery?.reisbalansV2?.v2Organization.budgetGroupsForWrpm || []

    if (budgetGroups.length) {
      const defaultBudgetGroupNamesArray = getDefaultBudgetGroupNames(budgetGroups)

      setBudgetGroupOptions(getBudgetGroupOptions(budgetGroups))
      setDefaultBudgetGroupNames(defaultBudgetGroupNamesArray)
      setSelectedBudgetGroupNames(defaultBudgetGroupNamesArray)
      setStatistics(calculateStatistics(budgetGroups as IBudgetGroupWithSurvey[], locale))
      setShowExportButton(isDataDefinitive(budgetGroups as IBudgetGroup[]))
    }
  }, [budgetGroupsQuery, locale])

  const budgetGroupsForWrpm = budgetGroupsQuery?.reisbalansV2?.v2Organization.budgetGroupsForWrpm || []

  const {
    data: wrpmData,
    startPolling,
    stopPolling,
  } = useGetWrpmQuery({
    variables: {
      organizationId: `${organizationId}`,
      budgetGroupNames: selectedBudgetGroupNames,
      forYear: selectedYear.value,
    },
    skip: !organizationId || selectedBudgetGroupNames.length < 1,
  })

  const handleBudgetGroupChange = (budgetGroup: IBudgetGroupOption): void => {
    setSelectedBudgetGroup(budgetGroup)

    budgetGroup === defaultBudgetGroupOption
      ? setSelectedBudgetGroupNames(defaultBudgetGroupNames)
      : setSelectedBudgetGroupNames([budgetGroup.label])
  }

  useEffect(() => {
    startPolling(5000)

    if (wrpmData?.reisbalansV2?.wrpm) stopPolling()

    return () => {
      stopPolling()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrpmData])

  const tableData = useMemo(() => {
    if (!wrpmData) return []

    return createTableData(transformWRPMData(wrpmData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrpmData])

  const description = (
    <Trans
      i18nKey="In connection with the <portalLink>{{ linkText }}</portalLink>, you must provide an estimate of how many kilometers your employees have traveled. The automatic travel details of your employees are shown below. For an extra check, you can invite all or some of your employees to estimate their traveled kilometers. You can then easily compare these data with each other."
      values={{
        linkText: t('CO₂ legislation'),
      }}
      components={{
        portalLink: (
          <Link
            href="https://www.rvo.nl/onderwerpen/rapportage-wpm"
            ariaLabel={t('CO₂ legislation')}
            style={{ color: Colors.blue500 }}
          />
        ),
      }}
    />
  )

  return (
    <PageContainer
      header={{
        title: t('Passenger mobility legislation'),
        showLine: true,
      }}
    >
      <Fragment>
        {showMessage && (
          <Message
            variant="warning"
            description={<ExpandableText text={description} />}
            className={Styles.message}
            iconProps={{
              onClick: toggleMessage,
              icon: 'cross',
            }}
          />
        )}

        <div className={Styles.yearSelectWrapper}>
          <Select
            id="data-year"
            ariaLabel={t('Year')}
            onChange={(year) => setSelectedYear(year)}
            value={selectedYear}
            options={generateYearArray()}
          />
        </div>

        {showProgressInvitedEmployees && (
          <ProgressInvitedEmployees
            onInviteButtonClick={toggleSurvey}
            statistics={statistics}
            onResponseButtonClick={toggleResponse}
          />
        )}

        <div className={Styles.controlsContainer}>
          {/* Added this div since the select component doesn't allow a decent override of the width, see RBW-1095 */}
          <div className={Styles.budgetGroupWrapper}>
            <Select
              id="budgetgroup"
              ariaLabel={t('Budgetgroup')}
              onChange={handleBudgetGroupChange}
              value={selectedBudgetGroup}
              options={budgetGroupOptions}
            />
          </div>

          {enableExportFeature && showExportButton && (
            <Button
              variant="primary"
              size="medium"
              icon="download"
              onClick={(): void => setShowExportConfirmModal(true)}
            >
              {t('Export')}
            </Button>
          )}
        </div>

        {!wrpmData?.reisbalansV2?.wrpm && <PollingNotification year={selectedYear.value} />}

        {wrpmData?.reisbalansV2?.wrpm && <Table tableConfig={getTableConfig()} tableData={tableData} />}

        {showSideDrawer && budgetGroupsForWrpm && (
          <InviteBudgetGroups budgetGroups={budgetGroupsForWrpm} onClose={toggleSurvey} />
        )}

        {showResponseSideDrawer && (
          <SurveyResponseBudgetGroups budgetGroups={budgetGroupsForWrpm} onClose={toggleResponse} />
        )}

        {showExportConfirmModal && (
          <ConfirmModal
            content={t('Finalize')}
            description={t('The complete overview will be exported to an Excel file.')}
            cancelContent={t('Cancel')}
            onCancel={(): void => setShowExportConfirmModal(false)}
            confirmButtonProps={{
              content: t('Export_long'),
              onClick: handleConfirmExportClick,
            }}
          />
        )}
      </Fragment>
    </PageContainer>
  )
}
